import { useContext, useState } from "react";
//import { useHistory } from "react-router-dom";

import { Link } from "../ui-kit/Link";
import { AppContexts } from "../../providers";

const LoginForm = (props) => {
  let authCtx = useContext(AppContexts.AuthContext);

  let [creds, setCreds] = useState({
    email: "",
    password: ""
  })

  const login = (e) => {
    e.preventDefault();

    authCtx
      .login(creds.email, creds.password)
      .then((user, err) => {
        if (!err) {
          window.location.assign("/");
        }
        window.location.assign("/");
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const update = (key, evt) => {
    setCreds({...creds, [key]: evt.target.value})
  };

  return (
    <div className="page-box sign-in">
      <h2 className="uk-heading-small">Sign In</h2>
      <div className="uk-margin">
        <form className="uk-form-stacked" onSubmit={login}>
          <div className="uk-margin">
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                value={creds.email}
                onInput={(e) => {update("email", e)}}
              />
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
              <input
                type="password"
                className="uk-input"
                value={creds.password}
                onInput={(e) => {update("password", e)}}
              />
            </div>
          </div>

          <button className="uk-button uk-button-# hms-btn hms-red-btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
