import axios from "axios";

let apiHost = ``
if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "local") {
  apiHost = `http://localhost:4000/api`
} else if (process.env.REACT_APP_ENV === "dev") {
  apiHost = `https://4t7cwt8tyk.execute-api.us-east-2.amazonaws.com/dev/api`
} else if (process.env.REACT_APP_ENV === "staging") {
  apiHost = `https://4t7cwt8tyk.execute-api.us-east-2.amazonaws.com/staging/api`
} else if (process.env.REACT_APP_ENV === "prod") {
  apiHost = `https://api.staging.ruebarue.com/beta/api`
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${ getCookie("admin_token") }`
  },
  withCredentials: true,
  baseURL: `${apiHost}/v2`
});

axiosInstance.interceptors.request.use(function (config) {
  return config;
});

export default axiosInstance;
export const host = apiHost;
