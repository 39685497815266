import { Routes, Route } from "react-router-dom";

import Authorize from "../../AuthorizedRoutes";

import LoginForm from "../auth/login";
import SwitchForm from "../auth/switch";

import Dashboard from "../dashboard";
import MessageAudit from "../messages/audits";
import AccountSettings from "../accounts";

const AppLayout = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Authorize><Dashboard /></Authorize>} />
      <Route path="/messages"> 
        <Route path="audits" element={<Authorize><MessageAudit /></Authorize>} />
      </Route>
      <Route path="/accounts"> 
        <Route path="settings" element={<Authorize><AccountSettings /></Authorize>} />
      </Route>
      <Route path="/auth">
        <Route path="login" element={<LoginForm />} />
        <Route path="switch" element={<Authorize><SwitchForm /></Authorize>} />
      </Route>
      <Route path="*" element={<LoginForm />} />
    </Routes>
  );
};

export default AppLayout;
