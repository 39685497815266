export default {
    mixins: [],

    props: {
        lines: Number
    },

    data: {
        lines: 3,
    },

    connected() {
        this.$el.classList.add("uk-line-clamp")
        this.$el.style.lineClamp = this.lines   
        this.$el.style.webkitLineClamp = this.lines   
    }
}