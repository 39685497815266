import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import UIkit from "uikit";
import moment from "moment";

import { AppContexts } from "../../providers";

const Dashboard = () => {
  const { account } = useContext(AppContexts.AuthContext);
  
  useEffect(() => {
    
  }, []);

  return (
    <section id="dashboard-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">
          Welcome, {`${account.user.first_name} ${account.user.last_name}`}
        </h1>
      </div>
      <div id="hms-main-body">
        <h3 className='uk-heading-small'>Links</h3>

        <ul>
          <li>
            <a href="/auth/switch">Switch Accounts</a>
          </li>
          <li>
            <a href="/messages/audits">Message Audits</a>
          </li>
          <li>
            <a href="/accounts/settings">Account Settings</a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Dashboard;
