import { createContext, useState } from "react";

import Http from "../Http.js";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [inbox, setInbox] = useState(null);
  const [users, setUsers] = useState([]);
  
  const refresh = async () => {
    let [accountResp, inboxResp] = await Promise.allSettled([
      Http.get("/admin/current")
    ]);

    let account = accountResp.value?.data;
    if (!account) {
      setAccount(null);
      setLoading(false);

      return;
    }

    setAccount(account);
    setLoading(false);
  };

  const search = (search) => {
    if (!!search) {
      return Http.post("/admin/search", {search: search})
        .then((resp, err) => {
          setUsers(resp.data.users);
        })
        .catch((err) => {
          setUsers([]);
        });
    } else {
      setUsers([]);
      return Promise.resolve();
    }
  };

  const switch_user = (user_id) => {
    return Http.post("/admin/switch", {user_id: user_id})
      .then((resp, err) => {
        window.location.assign("https://app.ruebarue.com")
      }).catch((err) => {
        console.log("Error Switching User", err)
      })
  };

  const login = (email, password) => {
    var fd = new FormData()
        fd.append("email", email)
        fd.append("password", password)

    return Http.post("/admin/login", fd)
      .then((resp, err) => {
        setAccount(resp.data.host);

        return Promise.resolve(resp.data.host);
      })
      .catch((err) => {
        setAccount(null);
      });
  };

  const logout = () => {
    return Http.post("/admin/logout")
      .then((resp, err) => {
        if (err !== null) {
          window.location.assign("/");
        } else {
          console.warn(err);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  let authCtx = {
    loading,

    account,
    inbox,
    users,

    login,
    logout,
    
    refresh,
    search,
    switch_user
  };

  return (
    <AuthContext.Provider value={authCtx}>{children}</AuthContext.Provider>
  );
};
