import moment from "moment";
import { useContext, useState, rerender } from "react";
//import { useHistory } from "react-router-dom";

import { Link } from "../ui-kit/Link";
import { AppContexts } from "../../providers";

const AccountSettings = (props) => {
  let { accounts, setAccounts, searchByCompany, toggleFlag } = useContext(
    AppContexts.AdminContext,
  );

  let [name, setName] = useState("");
  let [account, setAccount] = useState(null);

  const updateSearch = (evt) => {
    let searchTerm = evt.target.value;

    setAccount(null);
    setName(searchTerm);
    searchByCompany(searchTerm);
  };

  const selectAccount = (a) => {
    setName(a.settings?.company_name || name || "");
    setAccount(a);
    setAccounts([]);
  };

  const hasFlag = (flag) => {
    if (!account) {
      return;
    }

    return !!account.flags.find((f) => f.name === flag);
  };

  const handleToggleFlag = async (flag) => {
    if (!account) {
      return;
    }

    let [update, updateErr] = await toggleFlag(
      account.account_id,
      "account",
      flag,
    );
    if (updateErr !== null) {
      console.log(updateErr);
      return;
    }

    if (!!account.flags.find((f) => f.name === flag)) {
      account.flags = account.flags.filter((f) => f.name !== flag);
    } else {
      account.flags.push({ name: flag });
    }

    setAccount(JSON.parse(JSON.stringify(account)));
  };

  const flagsOptions = {
    import_with_rental_only: "Import only reservations with an active property",
    import_owners_as_guests: "Import owner reservations as guest reservations",
  };

  return (
    <div className="page-box uk-padding msg-audits">
      <h2 className="uk-heading-small">Get Message Audit</h2>
      <div className="">
        <form className="uk-form">
          <div className="uk-flex uk-inline-flex">
            <div className="uk-width-1-4">
              <div className="uk-margin">
                <label className="uk-form-label">Company Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    placeholder="Search for accounts by company name"
                    value={name || ""}
                    onInput={updateSearch}
                  />
                </div>
              </div>
              {!!accounts.length && (
                <ul className="uk-list dropdown-list">
                  {accounts.map((a) => {
                    return (
                      <li
                        onClick={() => {
                          selectAccount(a);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {`${a.settings?.company_name} - ${a.owner?.first_name || ""} ${a.owner?.last_name || ""}`}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="content">
        <label>Account Flags</label>

        {!!account && (
          <ul className="uk-list">
            {Object.keys(flagsOptions).map((f) => {
              return (
                <li>
                  <label for={f} className="switch">
                    <input
                      type="checkbox"
                      id={f}
                      checked={hasFlag(f)}
                      onInput={() => {
                        handleToggleFlag(f);
                      }}
                    />
                    <span className="toggle"></span>
                  </label>
                  <div className="switch-label">{flagsOptions[f]}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
