import { useContext, useEffect } from "react";
import { AppContexts } from "../../../providers";

const Footer = (props) => {
  const { user: account, inbox, logout }  = useContext(AppContexts.AuthContext);
  
	return (
		<>
			<section id="hms-footer">
	      <span>
	        Signed in as: { account?.user?.email || "" }
	      </span>
	      <span>
	        SMS Number: { inbox?.phone || "" }
	      </span>
			</section>
		</>
	);
};

export default Footer;
