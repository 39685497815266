import moment from "moment";
import { useContext, useState } from "react";
//import { useHistory } from "react-router-dom";

import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

const MessageAudit = (props) => {
  let {
    audit, 
    getAudit, 
    accounts,
    setAccounts, 
    reservations,
    setReservations, 
    searchByGuestName, 
    searchByCompany
  } = useContext(AppContexts.AdminContext);

  let [name, setName] = useState("")
  let [guest, setGuest] = useState("")

  let [date, setDate] = useState(moment());
  let [account, setAccount] = useState(null);
  let [reservation, setReservation] = useState(null)

  const updateSearch = (evt) => {
    let searchTerm = evt.target.value;

    setName(searchTerm);
    searchByCompany(searchTerm)
  };

  const updateGuest = (evt) => {
    let searchTerm = evt.target.value;

    setGuest(searchTerm);
    if (!!account && !!date) {
      searchByGuestName(account, date.format("YYYY-MM-DD"), searchTerm)
    }
  }

  const selectAccount = (a) => {
    setName(a.settings?.company_name || name || "")
    setAccount(a);
    setAccounts([])
  };

  const selectDate = (e) => {
    let d = moment(e.target.value, "YYYY-MM-DD")
    setDate(d)

    // if (!!account && !!reservation) {
    //   // getAudit(account, d.format("YYYY-MM-DD"))
    // }
  };

  const selectReservation = (reservation) => {
    setGuest(`${reservation.first_name } ${reservation.last_name}`)
    setReservation(reservation);
    setReservations([]);

    if (!!account) {
       getAudit(account, reservation)
    } 
  }

  return (
    <div className="page-box uk-padding msg-audits">
      <h2 className="uk-heading-small">Get Message Audit</h2>
      <div className="">
        <form className="uk-form">
          <div className='uk-flex uk-inline-flex'>
            <div className='uk-width-1-4'>
              <div className="uk-margin">
                <label className="uk-form-label">Company Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    placeholder="Search for accounts by company name"
                    value={name || ""}
                    onInput={updateSearch}
                  />
                </div>
              </div>
              {!!accounts.length && (
                <ul className='uk-list dropdown-list'>
                  {accounts.map((a) => {
                    return (
                      <li onClick={() => { selectAccount(a) }} style={{cursor: "pointer"}}>
                        {
                          `${a.settings?.company_name} - ${a.owner?.first_name || ""} ${a.owner?.last_name || ""}`
                        }
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
            <div className='uk-width-1-4'>
              <div className="uk-margin">
                <label className="uk-form-label">Check In Date</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    type='date'
                    value={date.format("YYYY-MM-DD")}
                    onInput={selectDate}
                  />
                </div>
              </div>
            </div>
            <div className='uk-width-1-4'>
              <div className="uk-margin">
                <label className="uk-form-label">Guest Name</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    value={guest}
                    onInput={updateGuest}
                  />
                </div>
              </div>
              {!!reservations.length && (
                <ul className='uk-list dropdown-list'>
                  {reservations.map((r) => {
                    return (
                      <li onClick={() => { selectReservation(r) }} style={{cursor: "pointer"}}>
                        {
                          `${r.first_name} ${r.last_name} - ${r.pms_id} - ${r.category}`
                        }
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className='content'>
        <table className="uk-table">
          <thead>
            <tr>
              <th>Message Name</th>
              <th>Guest Name</th>
              <th>Property</th>
              <th>Check In/Out</th>
              <th>Sent</th>
              <th>Unsent Reason</th>
            </tr>
          </thead>
          <tbody>
            {
              !!audit && audit.map((msg) => {
                return (  
                  <tr>
                    <td>{msg.scheduled_message?.name}</td>
                    <td>{msg.guest_reservation?.first_name} {msg.guest_reservation?.last_name}</td>
                    <td>{msg.guest_reservation?.pms_id}</td>
                    <td>{msg.guest_reservation?.check_in}/{msg.guest_reservation?.check_out}</td>
                    <td>{!!msg.sent ? "True" : "False"}</td>
                    <td>{msg.error}</td>
                  </tr>
                )}
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

/*
  //   <li>
  //     <a className="uk-accordion-title">{msg.scheduled_message.scheduled_message_id}: {msg.scheduled_message?.name} - {msg.reservation_count}</a>
  //     <div className="uk-accordion-content">
  //       <div><label>Send Date:</label> {moment.utc(msg.sent_at).format("M/D/YYYY")}</div>
  //       <div><label>Send Time:</label> {msg.scheduled_message.send_time}</div>
  //       <ul className='uk-list uk-list-striped'>
  //         <table class="uk-table">
  //           <thead>
  //             <tr>
  //               <th>Guest Name</th>
  //               <th>Property</th>
  //               <th>Check In/Out</th>
  //               <th>Sent</th>
  //               <th>Unsent Reason</th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {msg.scheduled_message.reciepts.map((r) => (
  //               <tr>
  //                 <td>{r.guest_reservation?.first_name} {r.guest_reservation?.last_name}</td>
  //                 <td>{r.guest_reservation?.pms_id}</td>
  //                 <td>{r.guest_reservation?.check_in} {r.guest_reservation?.check_out}</td>
  //                 <td>{!!r.sent ? "True" : "False"}</td>
  //                 <td>{r.error}</td>
  //               </tr>
  //             ))}
  //           </tbody>
  //         </table>
  //       </ul>
  //     </div>
  //   </li>
*/

export default MessageAudit;
