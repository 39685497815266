import { createContext, useState } from "react";

import Http from "../Http.js";

export const AdminContext = createContext();
export const AdminProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [audit, setAudit] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [reservations, setReservations] = useState([]);
  
  const getAudit = async (account, reservation) => {
    return Http.post("/admin/scheduler/audit_reservation", {
      account_id: account.account_id, 
      guest_reservation_id: reservation.guest_reservation_id
    }).then((resp, err) => {
      setAudit(resp.data.audit);
    }).catch((err) => {
      setAudit([]);
    });
  };

  const searchByGuestName = async(account, date, name) => {
    return Http.post("/admin/search_guest_name", {account_id: account.account_id, date: date, name: name})
      .then((resp, err) => {
        setReservations(resp.data);
      })
      .catch((err) => {
        setReservations([]);
      });
  }

  const searchByCompany = (searchTerm) => {
    if (!!searchTerm) {
      return Http.post("/admin/search_by_company", {search: searchTerm})
        .then((resp, err) => {
          setAccounts(resp.data.accounts);
        })
        .catch((err) => {
          setAccounts([]);
        });
    } else {
      setAccounts([]);
      return Promise.resolve();
    }
  };

  const toggleFlag = (item_id, item_type, flag) => {
    return Http.post("/admin/flags/toggle", {item_id, item_type, flag})
      .then((resp, err) => {
        return [resp.data, null];
      })
      .catch((err) => {
        return [null, err]
      });
  }

  let adminCtx = {
    loading,
    
    audit,
    getAudit,

    accounts,
    setAccounts,
    searchByCompany,

    toggleFlag,

    reservations,
    setReservations,
    searchByGuestName
  };

  return (
    <AdminContext.Provider value={adminCtx}>{children}</AdminContext.Provider>
  );
};
