import { useContext, useState } from "react";
//import { useHistory } from "react-router-dom";

import { Link } from "../ui-kit/Link";
import { AppContexts } from "../../providers";

const SwitchForm = (props) => {
  let {search, switch_user, users} = useContext(AppContexts.AuthContext);

  let [email, setEmail] = useState("")

  const handle_switch = (user_id) => {
    switch_user(user_id).catch((err) => {
      console.log("Error Switching User")
    })
  };

  const update = (evt) => {
    let searchTerm = evt.target.value;

    setEmail(searchTerm);
    search(searchTerm)
      .then((users, usersErr) => {
        console.log("Found Users:", users)
      }).catch((usersErr) => {
        console.log("Error searching for users", usersErr)
      })
  };

  return (
    <div className="page-box sign-in">
      <h2 className="uk-heading-small">Switch Accounts</h2>
      <div className="uk-margin">
        <form className="uk-form-stacked" onSubmit={handle_switch}>
          <div className="uk-margin">
            <label className="uk-form-label">Search</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                placeholder="Search for users by email"
                value={email}
                onInput={update}
              />
            </div>
          </div>
          <hr />
          <ul className='uk-list'>
            {users.map((user) => {
              return (
                <li onClick={() => { handle_switch(user.user_id) }} style={{cursor: "pointer"}}>
                  {user.first_name} {user.last_name} - {user.email}
                </li>
              )
            })}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default SwitchForm;
