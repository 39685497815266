// Import package components
import { useContext, useEffect } from "react";
//import { useHistory } from "react-router-dom";

import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const Header = (props) => {
  const { account, inbox, logout } = useContext(AppContexts.AuthContext);

  const handleLogout = async () => {
    console.log("logging out");

    logout()
      .then(() => {
        console.log("Goodbye");
      })
      .catch((err) => {
        console.warn("Error logging out:", err);
      });
  };

  useEffect(() => {
    window.innerWidth <= 1030 && handleNavbarCollapse();
    window.onresize = () => {
      // window.innerWidth <= 1030
      //   ? handleNavbarCollapse()
      //   : (document.getElementById("navbar_collapse").hidden = false);
    };
  }, []);

  useEffect(() => {
    console.log("account:", account);
  }, [account]);

  const handleNavbarCollapse = () => {
    // document.getElementById("navbar_collapse").hidden = true;
  };

  return (
    <nav className='uk-navbar-container hms-navbar uk-navbar'>
      <div className='uk-navbar-right'>
        { !!account && (
          <a className='header-right' href="#" onClick={handleLogout}>
            Sign Out
          </a>
        )}
      </div>
    </nav>
  );
};

export default Header;
