import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import AppLoader from "./components/layouts/app-loader";

import { AppProviders } from "./providers";

function App() {
  return (
    <AppProviders>
      <CookiesProvider>
        <BrowserRouter>
          <AppLoader  />
        </BrowserRouter>
      </CookiesProvider>
    </AppProviders>
  );
}

export default App;

