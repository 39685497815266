import { useContext } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { AppContexts } from "./providers";

const AuthorizedRoute = ({children}) => {
  let { account } = useContext(AppContexts.AuthContext);
  if (!account) {
    return (
      <Navigate to="/auth/login" replace={true} />
    )
  }

  return children
};

export default AuthorizedRoute;
