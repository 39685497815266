import { useEffect, useContext } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import { AppContexts } from "../../providers/";

import AppLayout from "./app-layout";

import Header from "./Header";
import Footer from "./Footer";

// import styled components
import Loading from "./Loading";

// Expiremental
import ViewportBox from "../ui-kit/components/viewport-box";
import LineClamp from "../ui-kit/components/line-clamp";

const AppLoader = () => {
  console.log("Loading App");
  const { account, inbox, refresh, loading } = useContext(AppContexts.AuthContext);

  useEffect(() => {
    console.log("Refreshing")
    refresh();
  }, []);

  useEffect(() => {
    window.UIkit.component("ViewportBox", ViewportBox);
    window.UIkit.component("LineClamp", LineClamp);
  }, [])

  return (
    <>
      <Header />
      {!loading ? <AppLayout /> : <Loading />}
      <Footer />
    </>
  );
};

export default AppLoader;
